import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'mtek-loading',
  standalone: true,
  imports: [NgClass, NgIf],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.css',
})
export class LoadingComponent {
  @Input({ required: true }) visible = false;
  @Output() visibleChange = new EventEmitter<boolean>();
}
